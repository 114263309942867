import {
  bootstrapApplication,
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions
} from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import {enableProdMode} from '@angular/core';
import { environment } from 'src/environments/environment';
import {  HttpRequest } from '@angular/common/http';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
.catch((err) => console.error(err)), {
  providers: [
    provideClientHydration(
      withEventReplay(), 
      withHttpTransferCacheOptions({
      includePostRequests: true
    })),
    withHttpTransferCacheOptions({
      filter: (req: HttpRequest<unknown>) => true, // to filter
      includeHeaders: [], // to include headers
      includePostRequests: true, // to include POST
      includeRequestsWithAuthHeaders: false, // to include with auth
    })
  ]
}
