import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection, inject, provideAppInitializer } from '@angular/core';
import {
  provideRouter,
  withRouterConfig,
  InMemoryScrollingOptions, 
  InMemoryScrollingFeature, 
  withInMemoryScrolling
} from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { NgxObserveModule } from 'ngx-observe';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { ngxsConfig } from './ngxs.config';
import { stateRollUp } from './store/index.state';
import { NgxsModule } from '@ngxs/store';
import { UrlHelper } from './store/helpers/url-helper';
import { NgSelectModule } from '@ng-select/ng-select';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DashboardSort } from './store/helpers/dashboard-sort';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleDataLayerService } from './services/google-data-layer.service';
import { CookieService } from 'ngx-cookie-service';
import { provideUserIdleConfig } from 'angular-user-idle';
import { ApiService } from './services/api.service';
import {
  BaseCustomTextService,
  BaseDashboardQueueService,
  BaseDashboardService,
  BaseFieldsOptionsService,
  BaseInstitutionService,
  BaseNavigationService,
  BaseNoteService,
  BasePromotionService,
  BaseSavedSearchService,
  BaseSearchService,
  BaseSpecialtyService,
  BaseUserService,
  BaseVacantPositionService,
  BaseVideoService,
  BaseAciListService,
  BaseAciService,
  BaseApiService
} from './services/base';
import {
  CustomTextService,
  DashboardQueueService,
  DashboardService,
  FieldsOptionsService,
  GatingService,
  InstitutionService,
  NavigationService,
  NoteService,
  PromotionService,
  SavedSearchService,
  SearchService,
  SpecialtyService,
  UserService,
  VacantPositionService,
  VideoService,
  AciListService,
  AciService
} from './services';
import { AppInitializerService } from './app-initializer';
import { DashboardApiService } from './store/services/dashboard-api.service';
import {
  provideNgxMask,
  NgxMaskDirective,
  NgxMaskPipe
} from 'ngx-mask';
import {
  BearerAuthInterceptor,
  NoCacheInterceptor
} from './interceptors/index';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { CustomPreloading } from './custom-preloading';
import { APP_BASE_HREF } from '@angular/common';
import { routes } from './app.routes'

const CUSTOM_PRELOADING_PROVIDER = [CustomPreloading];

export function loadApiEnvironment(appInitializerService: AppInitializerService) {
  return () => appInitializerService.loadApiEnvironment();
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      NgxsModule.forRoot([...stateRollUp],
        ngxsConfig), NgxsReduxDevtoolsPluginModule.forRoot(),
      NgxsDispatchPluginModule.forRoot(),
    ),
    NgxMaskDirective,
    NgxMaskPipe,
    provideRouter(
      routes,
      // withDebugTracing(),
      inMemoryScrollingFeature,
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
    ),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideAnimations(),
    provideUserIdleConfig({ idle: 900, timeout: 300, ping: 120 }), // 15 minutes, 5 minutes, 2 minutes
    provideAngularSvgIcon(),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    NgxObserveModule,
    NgxsReduxDevtoolsPluginModule,
    NgxsDispatchPluginModule,
    UrlHelper,
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheInterceptor,
      multi: true
    },
    provideAppInitializer(() => {
        const initializerFn = (loadApiEnvironment)(inject(AppInitializerService));
        return initializerFn();
      }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    { provide: BaseCustomTextService, useClass: CustomTextService },
    { provide: BaseDashboardService, useClass: DashboardService },
    { provide: BaseDashboardQueueService, useClass: DashboardQueueService },
    { provide: BaseFieldsOptionsService, useClass: FieldsOptionsService },
    { provide: BaseInstitutionService, useClass: InstitutionService },
    { provide: BaseNavigationService, useClass: NavigationService },
    { provide: BasePromotionService, useClass: PromotionService },
    { provide: BaseSavedSearchService, useClass: SavedSearchService },
    { provide: BaseSearchService, useClass: SearchService },
    { provide: BaseSpecialtyService, useClass: SpecialtyService },
    { provide: BaseUserService, useClass: UserService },
    { provide: BaseVacantPositionService, useClass: VacantPositionService },
    { provide: BaseVideoService, useClass: VideoService },
    { provide: BaseNoteService, useClass: NoteService },
    { provide: BaseApiService, useClass: ApiService },
    { provide: BaseAciListService, useClass: AciListService },
    { provide: BaseAciService, useClass: AciService },
    UrlHelper,
    NoteService,
    DeviceDetectorService,
    DashboardApiService,
    CookieService,
    GoogleDataLayerService,
    GatingService,
    DashboardSort,
    CUSTOM_PRELOADING_PROVIDER,
    NgSelectModule
  ]
};
