import { Component, Input, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { PromotionModel } from '../../models';
import { isPlatformBrowser } from '@angular/common';
import { COMMON_DIRECTIVES } from '../basic';
@Component({
    selector: 'app-system-banner',
    templateUrl: './system-banner.component.html',
    styleUrls: ['./system-banner.component.scss'],
    imports: [COMMON_DIRECTIVES]
})
export class SystemBannerComponent implements OnInit {
  public showBanner = 'false';
  @Input() public promotions: PromotionModel[];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  public ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('closeBanner') === 'true') {
        this.showBanner = 'false';
      } else {
        this.showBanner = 'true';
      }
    }
  }

  public closeBanner(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.showBanner === 'true') {
        this.showBanner = 'false';
        sessionStorage.setItem('closeBanner', 'true');
      }
    }
  }
}
