import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { Component, Inject, isDevMode, OnDestroy, OnInit, PLATFORM_ID, APP_ID } from '@angular/core';
import { GoogleDataLayerService } from './services/google-data-layer.service';
import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { Observable, Subject, Subscription } from 'rxjs';
import { PromotionModel, UserModel } from './models';
import { NavigationStart, Router, RouterOutlet, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { LazyLoadScriptService } from './services/lazy-load-script.service';
import { MENUS } from "./models/data/menus";
import { UserIdleService } from 'angular-user-idle';
import { BannerComponent } from './banner/banner.component';
import { RibbonComponent } from '../lib/components/ribbon/ribbon.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SystemBannerComponent } from './shared/system-banner/system-banner.component';
import { AmaModalComponent } from '../lib/components/modal/modal.component';
import { ButtonComponent } from '../lib/components/button/button.component';
import { AmaFooterComponent } from '../lib/components/footer/footer.component';
import { FooterComponent } from './footer/footer.component';
import { UserService, PromotionService } from './services';
import { provideNgxMask } from 'ngx-mask';
import { CustomTextService } from './services/custom-text.service';
import { DashboardQueueService } from './services/dashboard-queue.service';
import { FieldsOptionsService } from './services/fields-options.service';
import { GatingService } from './services/gating.service';
import { InstitutionService } from './services/institution.service';
import { NavigationService } from './services/navigation.service';
import { SavedSearchService } from './services/saved-search.service';
import { SearchService } from './services/search.service';
import { SpecialtyService } from './services/specialty.service';
import { VacantPositionService } from './services/vacant-position.service';
import { AciListService } from './services/aci-list.service';
import { AciService } from './services/aci.service';
import { Select } from '@ngxs/store';
import { UserActions } from './store/user/actions/user.actions';
import { UserState, UserStateModel } from './store/user/state/user.state';
import { DashboardService } from './services/dashboard.service';
import { DashboardApiService } from './store/services/dashboard-api.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieService } from 'ngx-cookie-service';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import { UserServiceHelper } from './helpers/user-service.helper';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    imports: [
        RouterOutlet,
        BannerComponent,
        RibbonComponent,
        NavigationComponent,
        SystemBannerComponent,
        AmaModalComponent,
        ButtonComponent,
        FooterComponent,
        AmaFooterComponent,
        AngularSvgIconModule
    ],
    providers: [
        CookieService,
        UserService,
        LazyLoadScriptService,
        UserIdleService,
        AuthService,
        GoogleDataLayerService,
        PromotionService,
        CustomTextService,
        DashboardQueueService,
        DashboardService,
        FieldsOptionsService,
        GatingService,
        InstitutionService,
        NavigationService,
        SavedSearchService,
        SearchService,
        SpecialtyService,
        VacantPositionService,
        AciListService,
        AciService,
        DashboardApiService,
        provideNgxMask()
    ]
})

export class AppComponent implements OnInit, OnDestroy {
  
  private subscriptions: Subscription[] = [];
  private getCurrentUserSubscription: Subscription;
  public getMenuItemsSubscription: Subscription;
  private readonly siteMenus: string[] = ['product', 'main', 'global', 'footer'];
  public bannerPromotions: PromotionModel[] = [];
  public systemWideMessage: PromotionModel[] = [];
  public amaNavigationMenuItems = [];
  public freidaMenus: any;
  public title = 'Freida';
  public offline: boolean;
  public promptEvent: any;
  public deferredPrompt: any;
  public showIdleModal = false;
  public showUserLoggedOutModal = false;
  public userMenuGroup: any;
  public bannerIsActivated: boolean = false;
  public jwtCookieValue: any;
  public closePromoBanner: boolean = false;
  public currentUser = this.userService.defaultUser;
  public pdHSessionId = 'PD-H-SESSION-ID';
  public pdId = 'PD-ID';
  public freidaJWT = 'FreidaJwt';
  public cookieNow = 0;
  public cookieAllt = 0;

  public getUserMenuGroup() {
    if (isPlatformBrowser(this.platformId)) {
      return [
        {
          text: this.authService.getAuthToken() === '' ? 'Sign In' : 'Sign Out',
          link:
            this.authService.getAuthToken() === ''
              ? this.authService.getSamlSignInUrl(window.location.href)
              : this.authService.getSamlSignOutUrl(window.location.href, window.location.hostname)
        },
        {
          text: 'Create free account',
          link: 'https://fsso.ama-assn.org/sign-up'
        },
        {
          text: 'Join / Renew',
          link: 'https://member.ama-assn.org/join-renew/'
        },
        {
          text: 'Member Benefits',
          link: 'https://freida.ama-assn.org/memberbenefits'
        },
        {
          text: 'AMA Account',
          link: 'https://amc.ama-assn.org/contact-preferences'
        },
        {
          text: 'Email Preferences',
          link: ' https://amc.ama-assn.org/contact-preferences'
        }
      ];
    }

    return [];
  }

  public user = [this.userService.defaultUser];
  public joinButtons = [
    {
      text: 'Join',
      link: 'https://member.ama-assn.org/join-renew/'
    },
    {
      text: 'Renew',
      link: 'https://member.ama-assn.org/join-renew/'
    }
  ];
  @Select(UserState)
  public user$: Observable<UserStateModel>;
  public currentUser$: Observable<UserModel>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private googleDataLayerService: GoogleDataLayerService,
    private userService: UserService,
    private authService: AuthService,
    private promotionService: PromotionService,
    private router: Router,
    private lazyLoadService: LazyLoadScriptService,
    private userIdle: UserIdleService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    @Inject(APP_ID) private appId: string,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // Get the current time and set it to the cookieNow variable
    this.cookieNow = dayjs().valueOf()

    dayjs.extend(duration)


    if(this.cookieService.check('allt')) {
      this.cookieAllt = this.cookieService.get('allt') ? parseInt(this.cookieService.get('allt')) : 0;
      // Get the allt cookie value and add 2 hours to it.
      this.cookieAllt = dayjs(this.cookieAllt).add(2, 'hours').valueOf();
    }

    if(this.cookieService.check(this.freidaJWT)) {
      this.getFreidaJwt()
    }

    console.warn(isDevMode());
    this.userMenuGroup = this.getUserMenuGroup();

    const navEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      this.googleDataLayerService.trackAnalytics({
        event: 'page', pageName: event.urlAfterRedirects
      });

      this.updatePreviousUrl(event.urlAfterRedirects);
    });
  }


  @Dispatch() public getUser = () => new UserActions.UpdateUser();
  @Dispatch() public updatePreviousUrl = (url: string) => new UserActions.UpdatePreviousUrl(url);

  public stayLoggedIn() {
    if(this.getFreidaJwt() < this.cookieNow && this.cookieAllt < this.cookieNow) {
      this.authService.refreshJwtToken().subscribe();
      this.userIdle.resetTimer();
      this.showIdleModal = false;
    } else if(this.cookieAllt > this.cookieNow) {
      this.authService.login();
    } else {
      this.showIdleModal = false;
    }
  }

  // Get the Freida JWT token
  public getFreidaJwt() {
    if (this.cookieService.check(this.freidaJWT)) {
      if(this.cookieService.get(this.freidaJWT) !== '') {
        const freidaJWTValue = this.cookieService.get('FreidaJwt');
        const freidaJWTdecoded = UserServiceHelper.decodeToken(freidaJWTValue);

        return parseInt(freidaJWTdecoded.exp);
      } else {
        return null;
      }
    }
  }

  public ngOnInit() {   
    if (isPlatformBrowser(this.platformId) && window) {
      if (sessionStorage.getItem('bannerAcknowlegement') === 'true') {
        this.bannerIsActivated = true
      }

      if(this.getFreidaJwt() < this.cookieNow && this.cookieAllt < this.cookieNow) {
        this.authService.refreshJwtToken().subscribe();
      }

      if(this.getFreidaJwt() > this.cookieNow && this.cookieAllt < this.cookieNow) {
        this.cookieService.delete('FreidaJwt');
      }
    }

    // DISPATCH UPDATE USER
    this.getUser();
    this.setUpHashRedirects();

    // get User Selector from Store, set user var if user is available.
    this.getCurrentUserSubscription = this.user$
    .subscribe((user) => {
      this.currentUser = user.user;

      if (this.currentUser) {
        this.user = [this.currentUser];
        if (this.currentUser.uuid) {
          // Start watch when time is up. 
          this.userIdle.startWatching();

          // If user is idle for 10 minutes, then show modal. 
          // If a user doesn't make a selection in 5 minutes, then sign out. 
          // Ping every 2 minutes to see if there is activity.
          this.userIdle.ping$.subscribe(() => {
            // Check to see if this.pdHSessionId has expired. If it has then refresh the token by going by flexing FSSO Url
            if (this.cookieAllt > this.cookieNow && this.getFreidaJwt() > this.cookieNow) {
              this.authService.login();
            }
          });

          // Start watching when user idle is starting.
          this.userIdle.onTimerStart().subscribe(() => {
            this.showIdleModal = true;
          });

          // Start watch when time is up.
          this.userIdle.onTimeout().subscribe(() => { 
            this.signOut()
            });
        }
      }
      else {
        // Add action to save default User to state
        this.user = [this.userService.defaultUser];
      }

      if (this.currentUser.tam_id) {
        this.googleDataLayerService.setUserAnalyticsData(this.currentUser);
      }
    });

    // Auto Sign in Function

    if (isPlatformBrowser(this.platformId) && document) {
      const isSignedIn = sessionStorage.getItem('isSignedIn');
      // pattern to check if the pdHSessionId is not empty
      const pattern = '^1';
      const referrerURl = document.referrer;
      const domainUrl = document.location.hostname;

      this.getCurrentUserSubscription = this.user$
      .subscribe((user) => {
        this.currentUser = user.user;
      });
      
      if (!isSignedIn) {
        // Check to see if the user is coming from the join-renew page or the ama-assn.org page and if the referrer url does not contain freida
        if(referrerURl.includes('join-renew') || !domainUrl.includes('freida') && referrerURl.includes('ama-assn.org')) {
          this.authService.login();
        }

        // Check if pdHSessionId and allt cookies are present and if the pdHSessionId is not empty
        if (this.cookieService.check(this.pdHSessionId) // Check to see if the pdHSessionId exists
          && this.cookieService.get(this.pdHSessionId).length > 0 // Check to see if the pdHSessionId value is not empty
          && this.cookieService.get(this.pdHSessionId).match(pattern) // Check to see if the pdHSessionId is not empty
          && this.cookieService.check('allt') // Check to see if the allt cookie exists
          && this.cookieAllt < this.cookieNow // Check to see if the allt cookie is less than the current time
        ) {
          this.authService.login();
          sessionStorage.setItem('isSignedIn', 'true');

          // Check to see if the pdHSessionId exists and if it is empty. If it is empty then log the user out
        } else if (this.cookieService.check(this.pdHSessionId) && this.cookieService.get(this.pdHSessionId).length === 0 && this.cookieService.check(this.freidaJWT) || 
          this.cookieService.check(this.pdId) && this.cookieService.get(this.pdId).length === 0 && this.cookieService.check(this.freidaJWT)) {
          this.authService.logout();
        }
      } else {
        sessionStorage.removeItem('isSignedIn');
      }
    }

    this.freidaMenus = MENUS;
    this.amaNavigationMenuItems = MENUS.global;

    this.subscriptions.push(
      this.promotionService.getAnnoucementBanner()
        .pipe(takeUntil(this.destroy$))
        .subscribe((promos) => {
          this.bannerPromotions = promos;
        })
    );

    this.subscriptions.push(
      this.promotionService.getSystemWideMessage()
        .pipe(takeUntil(this.destroy$))
        .subscribe((promos) => {
          this.systemWideMessage = promos;
        })
    );

    // Load External scripts
    this.lazyLoadService.loadScript('/assets/scripts/google-analytics.js');

    
    // Load New Relic only in PROD
    if (isPlatformBrowser(this.platformId) && window) {
      if (window.location.href.indexOf("freida") > -1) {
        this.lazyLoadService.loadScript('/assets/scripts/newrelic.js');
      }
    }
  }


  public learnMoreClicked(bannerUrl) {
    if (isPlatformBrowser(this.platformId) && window) {
      window.open(bannerUrl[0].ctaURL, '_blank');
    }
  }

  public closeSystemBanner() {
    if (isPlatformBrowser(this.platformId) && window) {
      this.bannerIsActivated = true;
      sessionStorage.setItem('bannerAcknowlegement', 'true');
    }
  }

  private setUpHashRedirects() {
    const redirectPattern = /^\/(Freida)?#/i;

    const routerNavigationSubscription =
      this.router.events.pipe(
        filter(event => event instanceof NavigationStart),
        takeUntil(this.destroy$)
      ).subscribe((event: NavigationStart) => {
        if (!!event.url && event.url.match(redirectPattern)) {
          this.router.navigateByUrl(event.url.replace(redirectPattern, ''));
        }
      });

    this.subscriptions.push(routerNavigationSubscription);
  }

  public signIn() {
    this.authService.login();
  }

  public signOut() {
    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    this.showIdleModal = false;
    this.showUserLoggedOutModal = false;
    this.authService.logout();
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    if (this.getCurrentUserSubscription) {
      this.getCurrentUserSubscription.unsubscribe();    }

    if (this.getMenuItemsSubscription) {
      this.getMenuItemsSubscription.unsubscribe();
    }

    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
