import { Routes } from '@angular/router';
import { MemberGuard, ProgramDirectorGuard, RedirectGuard } from './guards';
import { VacantPositionResolver } from './vacant-position/details/vacant-position.resolver';
import { VacantPositionFieldsResolver } from './vacant-position/details/vacant-position-fields.resolver';

export const routes: Routes = [
    {
      path: 'transition',
      loadComponent: () => import('./transition/transition.component').then(c => c.TransitionComponent)
    },
    {
      path: 'contact',
      loadComponent: () => import('./contact/contact.component').then(c => c.ContactPageComponent)
    },
    {
      path: 'faq',
      loadComponent: () => import('./faq/faq.component').then(c => c.FaqComponent)
    },
    {
      path: 'calculator',
      loadComponent: () => import('./calculator-landing/calculator-landing.component').then(c => c.CalculatorLandingComponent)
    },
    {
      path: 'road-to-residency-guide',
      children: [
        {
          path: 'resources',
          canActivate: [MemberGuard],
          loadComponent: () => import('./road-to-residency-guide/resources/resources.component').then(l => l.RoadToResidencyGuideResourcesComponent)
        },
        {
          path: 'timeline',
          canActivate: [MemberGuard],
          loadComponent: () => import('./road-to-residency-guide/timeline/timeline.component').then(l => l.RoadToResidencyGuideTimelineComponent)
        },
        {
          path: 'match',
          canActivate: [MemberGuard],
          loadComponent: () => import('./road-to-residency-guide/match/match.component').then(l => l.RoadToResidencyGuideMatchComponent)
        },
        {
          path: 'application-interview',
          canActivate: [MemberGuard],
          loadComponent: () => import('./road-to-residency-guide/interview/interview.component').then(l => l.RoadToResidencyGuideInterviewComponent)
        },
        {
          path: 'research',
          canActivate: [MemberGuard],
          loadComponent: () => import('./road-to-residency-guide/research/research.component').then(l => l.RoadToResidencyGuideResearchComponent)
        },
        {
          path: 'accreditation',
          canActivate: [MemberGuard],
          loadComponent: () => import('./road-to-residency-guide/accreditation/accreditation.component').then(l => l.RoadToResidencyGuideAccreditationComponent)
        },
        {
          path: 'overview',
          canActivate: [MemberGuard],
          loadComponent: () => import('./road-to-residency-guide/overview/overview.component').then(l => l.RoadToResidencyGuideOverviewComponent)
        },
        {
          path: '',
          loadComponent: () => import('./road-to-residency-guide/road-to-residency-guide.component').then(c => c.RoadToResidencyGuideComponent),
        },
        {
          path: '**',
          loadComponent: () => import('./road-to-residency-guide/road-to-residency-guide.component').then(l => l.RoadToResidencyGuideComponent)
        }
      ]
    },
    {
      path: 'memberbenefits',
      loadComponent: () => import('./member-benefits/member-benefits.component').then(c => c.MemberBenefitsComponent)
    },
    {
      path: 'dashboard',
      canLoad: [MemberGuard],
      loadComponent: () => import('./dashboard/dashboard.component').then(d => d.DashboardComponent),
      children: [
        { path: '', redirectTo: 'programs', pathMatch: 'full' },
        {
          path: 'programs',
          loadComponent: () => import('./dashboard/programs/programs-dashboard.component').then(d => d.ProgramsDashboardComponent),
          children: [
            {
              path: 'list',
              loadComponent: () => import('./dashboard/programs/list/list.component').then(d => d.ListComponent),
              pathMatch: 'full'
            },
            {
              path: 'list/aci',
              loadComponent: () => import('./dashboard/programs/list/list.component').then(d => d.ListComponent),
              pathMatch: 'full'
            },
            {
              path: 'map',
              loadComponent: () => import('./dashboard/map/map.component').then(d => d.DashboardMapComponent),
              pathMatch: 'full'
            },
            {
              path: 'map/aci',
              loadComponent: () => import('./dashboard/map/map.component').then(d => d.DashboardMapComponent),
              pathMatch: 'full'
            }
          ]
        },
        {
          path: 'compare',
          loadComponent: () => import('./dashboard/compare-view/compare-view.component').then(d => d.CompareViewComponent),
        },
        {
          path: 'calculator',
          canActivate: [RedirectGuard],
          loadComponent: () => import('./dashboard/programs/programs-dashboard.component').then(d => d.ProgramsDashboardComponent),
          data: {
            externalUrl: 'https://freidaresidencycalculator.com/36ff014be38228f3fa7341b8ea23d9dc/'
          }
        }
      ]
    },
    {
      path: 'dashboard-landing',
      loadComponent: () => import('./dashboard-landing/dashboard-landing.component').then(d => d.DashboardLandingComponent)
    },
    {
      path: 'director',
      children: [
        {
          path: '',
          loadComponent: () => import('./director/director.component').then(d => d.DirectorComponent),
          canActivate: [ProgramDirectorGuard],
        },
        {
          path: 'edit-program/:programNumber',
          loadComponent: () => import('./director/edit-program/edit-program.component').then(d => d.EditProgramComponent),
          canActivate: [ProgramDirectorGuard]
        }
      ]
    },
    {
      path: 'institution',
      children: [
        {
          path: ':institutionId',
          loadComponent: () => import('./institutions/details/institution-details.component').then(i => i.InstitutionDetailsComponent),
        },
        {
          path: '',
          loadComponent: () => import('./institutions/directory/institution-directory.component').then(i => i.InstitutionDirectoryComponent),
        }
      ],
      data: { load: true }
    },
    {
      path: 'program',
      children: [
        { path: ':pgmNbr', loadComponent: () => import('./program/program.component').then(p => p.ProgramComponent) },
      ],
      data: { load: true }
    },
    {
      path: 'program-resources-form',
      loadComponent: () => import('./program-resources-form/program-resources-form.component').then(p => p.ProgramResourcesFormComponent)
    },
    {
      path: 'obgyn-aci-program-survey',
      loadComponent: () => import('./aci-weights-survey/aci-weights-survey.component').then(a => a.AciWeightsSurveyComponent)
    },
    {
      path: 'residency-alignment',
      children: [
        {
          path: '',
          redirectTo: 'aci',
          pathMatch: 'full'
        },
        {
          path: 'aci',
          loadComponent: () => import('./aci/aci.component').then(a => a.AciComponent),
          children: [
            {
              path: 'questionnaire',
              loadComponent: () => import('./aci/form/aci-form.component').then(a => a.AciFormComponent),
              children: [
                {
                  path: 'question1',
                  loadComponent: () => import('./aci/form/aci-form.component').then(a => a.AciFormComponent),
                  pathMatch: 'full'
                },
                {
                  path: 'question2',
                  loadComponent: () => import('./aci/form/aci-form.component').then(a => a.AciFormComponent),
                  pathMatch: 'full'
                },
                {
                  path: 'question3',
                  loadComponent: () => import('./aci/form/aci-form.component').then(a => a.AciFormComponent),
                  pathMatch: 'full'
                },
                {
                  path: 'question4',
                  loadComponent: () => import('./aci/form/aci-form.component').then(a => a.AciFormComponent),
                  pathMatch: 'full'
                },
                {
                  path: 'question5',
                  loadComponent: () => import('./aci/form/aci-form.component').then(a => a.AciFormComponent),
                  pathMatch: 'full'
                },
                {
                  path: 'question6',
                  loadComponent: () => import('./aci/form/aci-form.component').then(a => a.AciFormComponent),
                  pathMatch: 'full'
                },
                {
                  path: 'preview',
                  loadComponent: () => import('./aci/form/aci-form.component').then(a => a.AciFormComponent),
                  pathMatch: 'full'
                }
              ],
            }
          ]
        },
        {
          path: 'results',
          loadComponent: () => import('./aci/results/results.component').then(a => a.ResultsComponent),
          children: [
            {
              path: 'map',
              loadComponent: () => import('./aci/map/map.component').then(a => a.MapComponent),
            },
  
            {
              path: 'list',
              loadComponent: () => import('./aci/list/list.component').then(a => a.ListComponent),
            },
  
          ]
        }
      ]
    },
    {
      path: 'residency-calculator',
      children: [
        {
          path: '',
          loadComponent: () => import('./calculator/introduction/introduction.component').then(a => a.IntroductionComponent)
        },
        {
          path: '',
          loadComponent: () => import('./calculator/steps/steps.component').then(a => a.StepsComponent),
          children: [
            {
              path: 'step-1',
              loadComponent: () => import('./calculator/step1/step1.component').then(a => a.Step1Component),
            },
            {
              path: 'step-2',
              loadComponent: () => import('./calculator/step2/step2.component').then(a => a.Step2Component)
            },
            {
              path: 'step-3',
              loadComponent: () => import('./calculator/step3/step3.component').then(a => a.Step3Component),
            },
            {
              path: 'step-4',
              loadComponent: () => import('./calculator/step4/step4.component').then(a => a.Step4Component),
            },
            {
              path: 'step-5',
              loadComponent: () => import('./calculator/step5/step5.component').then(a => a.Step5Component),
            },
          ]
        },
        {
          path: 'result',
          loadComponent: () => import('./calculator/result/result.component').then(a => a.ResultComponent),
        }
      ]
    },
    {
      path: 'search',
      loadComponent: () => import('./search/results/results.component').then(s => s.ResultsComponent),
      children: [
        {
          path: 'map',
          loadComponent: () => import('./search/map/map.component').then(m => m.MapComponent)
        },
        {
          path: 'map/aci',
          loadComponent: () => import('./search/map/map.component').then(m => m.MapComponent)
        },
        {
          path: 'list',
          loadComponent: () => import('./search/list/list.component').then(l => l.ListComponent)
        },
        {
          path: 'list/aci',
          loadComponent: () => import('./search/list/list.component').then(l => l.ListComponent)
        },
      ], data: { load: true }
    },
    {
      path: 'specialty',
      children: [
        {
          path: ':specialtyNumber',
          loadComponent: () => import('./specialty/specialty.component').then(l => l.SpecialtyComponent)
        },
        {
          path: '**',
          loadComponent: () => import('./specialty/landing/specialty-landing.component').then(l => l.SpecialtyLandingComponent),
          data: { load: true }
        }
      ],
    },
    {
      path: 'vacant-position',
      loadComponent: () => import('./vacant-position/vacant-position-wrapper.component').then(v => v.VacantPositionWrapperComponent),
      children: [
        { path: '', redirectTo: 'list', pathMatch: 'full' },
        {
          path: 'list',
          loadComponent: () => import('./vacant-position/list/list.component').then(v => v.VacantPositionListComponent),
        },
        {
          path: 'map',
          loadComponent: () => import('./vacant-position/map/map.component').then(v => v.VacantPositionMapComponent),
        },
        {
          path: ':vacantPositionTitle',
          loadComponent: () => import('./vacant-position/details/vacant-position.component').then(v => v.VacantPositionComponent),
          resolve: {
            vacantPosition: VacantPositionResolver,
            fields: VacantPositionFieldsResolver
          },
          providers: [
            VacantPositionFieldsResolver,
            VacantPositionResolver
          ]
        }
      ],
      data: { load: true }
    },
    {
      path: 'video-series',
      children: [
        {
          path: '',
          loadComponent: () => import('./video-series/video-series/video-series.component').then(v => v.VideoSeriesComponent),
        },
        {
          path: ':seriesTitle',
          loadComponent: () => import('./video-series/video-series/video-series.component').then(v => v.VideoSeriesComponent),
        },
        {
          path: ':seriesTitle/video/:videoTitle',
          loadComponent: () => import('./video-series/video-series/video-series.component').then(v => v.VideoSeriesComponent),
        },
        {
          path: 'tours/:videoTitleId',
          loadComponent: () => import('./video-series/video-series/video-series.component').then(v => v.VideoSeriesComponent),
        },
      ],
      data: { load: true }
    },
    { path: 'video-gallery', redirectTo: 'video-series', pathMatch: 'full' },
    { path: 'freida', pathMatch: 'prefix', redirectTo: '/' },
    { path: 'Freida', pathMatch: 'prefix', redirectTo: '/' },
    {
      path: '',
      loadComponent: () => import('./home/home.component').then(h => h.HomeComponent)
    },
    {
      path: '404', pathMatch: 'full',
      loadComponent: () => import('./not-found/not-found.component').then(m => m.NotFoundComponent)
    },
    {
      path: '**', pathMatch: 'full',
      loadComponent: () => import('./not-found/not-found.component').then(m => m.NotFoundComponent)
    },
  ];