<app-banner [promotions]="bannerPromotions"
  (ctaClicked)="learnMoreClicked(bannerPromotions)">
</app-banner>
<ama-ribbon [globalMenuItems]="amaNavigationMenuItems"
  [joinButtons]="joinButtons"
  [userMenuGroup]="userMenuGroup"
  [user]="user"
  (userLogOut)="signOut()">
</ama-ribbon>
  <main role="main">
    <app-navigation [menuItems]="freidaMenus?.main"></app-navigation>
    <app-system-banner [promotions]="systemWideMessage"></app-system-banner>
    <article role="article"
             class="content">
      <router-outlet></router-outlet>
    </article>
  </main>
  <ama-modal [show]="showIdleModal">
    <div class="add-program">
      <div>
        <p style="text-align: center">Would you like to continue your session?</p>
      </div>
      <div class="add-program__cta">
        <div class="half">
          <ama-button buttonTheme="primary"
                      text="YES"
                      class="freida-primary-solid"
                      (click)="stayLoggedIn()">
          </ama-button>
        </div>
        <div class="half">
          <ama-button buttonTheme="primary"
                      text="NO"
                      class="freida-primary--outline"
                      (click)="signOut()">
          </ama-button>
        </div>
      </div>
    </div>
  </ama-modal>
  @defer {
    <app-footer [menuItems]="freidaMenus?.footer"></app-footer>
    <ama-footer></ama-footer>
  }


