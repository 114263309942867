import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Injectable, Input, OnChanges, ElementRef, HostListener, } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router, RouterLink } from '@angular/router';
import { Select } from '@ngxs/store';
import { combineLatestWith, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuItemModel, UserModel } from '../models';
import { AuthService } from '../services/auth.service';
import { UserState, UserStateModel } from '../store/user/state/user.state';
import { NgxObserveModule } from 'ngx-observe';

import { SearchBarComponent } from '../shared/search-bar/search-bar.component';
import { ButtonComponent } from '../../lib/components/button/button.component';
import { AmaIconComponent } from '../../lib/components/icon/icon.component';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    imports: [ButtonComponent, AmaIconComponent, SearchBarComponent, RouterLink, NgxObserveModule],
    animations: [
        trigger('openClose', [
            state('open', style({
                height: '*',
                opacity: '1'
            })),
            state('closed', style({
                height: 0,
                overflow: 'hidden',
                opacity: 0
            })),
            transition('open => closed', [
                animate('0.25s')
            ]),
            transition('closed => open', [
                animate('0.25s')
            ]),
        ]),
        trigger('mobileAnimation', [
            state('open', style({
                height: '100vh',
                opacity: '1'
            })),
            state('closed', style({
                height: 0,
                overflow: 'hidden',
                opacity: 0.65
            })),
            transition('open => closed', [
                animate('0.25s')
            ]),
            transition('closed => open', [
                animate('0.25s')
            ]),
        ]),
    ]
})

@Injectable()
export class NavigationComponent implements OnChanges {
  public value: 'Select';
  public isOpen = false;
  public mobileIsOpen = false;
  public freidaMenuName = 'main';
  public user: UserModel;
  public tooltipText = 'Join the AMA to get access to all the tools you need.';
  public menus: MenuItemModel[] = [];
  public menuLinks = {};
  public mobileTitle = '';
  public mobileIcon = 'close';
  public newNavArray: any = [];
  public newChildren: any = [];
  public newIndex: number;
  public level = 1;
  public showToolsResourcesMenu = false;

  @Input() private menuItems: MenuItemModel[];

  @Select(UserState.isMember)
  public isMember$: Observable<UserStateModel>;
  public isAuthenticated$: Observable<UserStateModel>;

  @Select(UserState)
  public user$: Observable<UserStateModel>;
  public currentUser$;
  private routeSub: Subscription;

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      if (this.mobileTitle !== 'Search Programs' && this.mobileTitle !== 'Tools & Resources') {
        this.mobileIsOpen = false;
        this.isOpen = false;
        this.showToolsResourcesMenu = false;
      }
    } else {
      if (targetElement.classList.contains("freida-navigation__search-drawer__related")
        || targetElement.classList.contains("freida-navigation__content__menus")
        || targetElement.classList.contains("freida-navigation__search-drawer__search")
        || targetElement.classList.contains("freida-navigation__search-drawer")
        || targetElement.classList.contains("mobile-navigation__item")) {


        this.isOpen = false;
        this.showToolsResourcesMenu = false;
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private elementRef: ElementRef
  ) {
    this.route.queryParams.subscribe(params => {
      this.mobileIsOpen = false;
      this.isOpen = false;
      this.showToolsResourcesMenu = false;
    });

    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // save your data
        this.mobileIsOpen = false;
        this.isOpen = false;
        setTimeout(() => { // settimeout needed here
          this.showToolsResourcesMenu = false;
        }, 0);
      }
    });

    this.currentUser$ = this.isMember$.pipe(
      combineLatestWith([this.user$]),
      map((state) => {
        const isMember = JSON.parse(JSON.stringify(state[0]));
        const user = JSON.parse(JSON.stringify(state[1]));
        user.user.isMember = isMember;
        this.user = user.user;
        return user.user;
      })
    );

  }

  public toggleToolsResourcesMenu() {
    this.showToolsResourcesMenu = !this.showToolsResourcesMenu;
  }

  public redirectToFaq() {
    this.router.navigate(['contact']);
  }

  public toggleMenu(flag?: string) {
    switch (flag) {
      case 'trigger':
        this.mobileIsOpen = !this.mobileIsOpen;
        break;
      case 'Search Programs':
        this.mobileTitle = 'Search Programs';
        this.mobileIcon = 'arrow-right';
        this.mobileIsOpen = true;
        this.isOpen = true;
        break;
      default:
        this.isOpen = !this.isOpen;
        this.showToolsResourcesMenu = false;
    }
  }

  public wasChange(value) {
    this.navigateTo(value.value);
  }

  public ngOnChanges() {
    if (this.menuItems) {
      const subMenus = this.menuItems.filter(m => m.children.length > 0).map(menu => ({ [menu.title]: menu.children.map(link => ({ value: link.url, text: link.title })) }));
      for (const subMenu of subMenus) {
        this.menuLinks = { ...this.menuLinks, ...subMenu };
      }
      this.menus = this.menuItems;
    }
  }

  public resetNav(): void {
    this.isOpen = false;
    this.showToolsResourcesMenu = false;
  }

  public navigateTo(url: string, user?: UserModel) {
    this.isOpen = false;
    this.showToolsResourcesMenu = false;
    const directorUrl = url.includes('/director');
    let nextUrl = url;

    if (user && !user.isAuthenticated && !user.isProgramMaintainer && directorUrl) {
      nextUrl = this.authService.getSsoSignInUrl(nextUrl);
    }

    if (nextUrl.startsWith('http')) {
      location.href = nextUrl;
      this.resetNav();
    } else {
      this.router.navigateByUrl(nextUrl);
      this.resetNav();
    }
  }

  public roadToResidencyLink(user: UserModel) {
    if (user && user.isMember) {
      this.router.navigate(['/road-to-residency-guide/overview']);
    } else {
      this.router.navigate(['/road-to-residency-guide']);
    }
  }

  public goToDashboard(user: UserModel) {
    if (user && user.isMember) {
      this.resetNav();
      this.router.navigate(['/dashboard-landing']);
      // window.location.href = '/dashboard-landing';
    }
  }

  public goToProgramDirectorPortal(user) {
    const nextUrl = this.authService.getSsoSignInUrl(window.location.hostname + '/director');
    if (user?.roles.includes('authenticated')) {
      this.resetNav();
      window.location.href = '/director';
    } else {
      window.location.href = nextUrl;
    }
  }

  public homeMenu() {
    this.mobileTitle = '';
    this.mobileIcon = 'close';
    this.level = 1;
  }

  public typeaheadSubmit($event) {
    this.toggleMenu('trigger');
  }

  public makeMobilePage(title: string, fromIndex: number, link?: string) {
    const arr: any = this.menus[fromIndex];
    const hasChildren = arr?.hasOwnProperty('children');

    if (hasChildren && arr.children.length > 0 && this.level < 2) {
      this.mobileTitle = title;
      this.newChildren = [];
      this.menus[fromIndex].children.forEach((c) => this.newChildren.push(c));
      this.mobileIcon = 'arrow-right';
      this.level = 2;
    } else {
      this.toggleMenu('trigger');
      this.navigateTo(link);
    }
  }
}
