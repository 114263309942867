import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgFor } from '@angular/common';
import { ClickOutsideDirective } from '../../../lib/components/clickOutside.directive';
import { Router } from '@angular/router';

@Component({
    selector: 'ama-ribbon',
    templateUrl: 'ribbon.component.html',
    styleUrls: ['ribbon.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                height: '*',
                opacity: '1'
            })),
            state('closed', style({
                height: 0,
                opacity: 0
            })),
            state('openUser', style({
                height: '*',
                opacity: '1'
            })),
            state('closeUser', style({
                height: 0,
                opacity: 0
            })),
            transition('open => closed', [
                animate('0.25s')
            ]),
            transition('closed => open', [
                animate('0.25s')
            ]),
            transition('openUser => closeUser', [
                animate('0.25s')
            ]),
            transition('closeUser => openUser', [
                animate('0.25s')
            ]),
        ]),
    ],
    imports: [ClickOutsideDirective, NgClass, NgFor]
})
export class RibbonComponent {

  @Input() public buttonText = 'Explore';
  @Input() public user = [];
  @Input() public globalMenuItems = [];
  @Input() public userMenuGroup = [];
  @Input() public joinButtons = [];
  @Output() public userLogOut = new EventEmitter<any>();

  public isOpen = false;
  public isUserOpen = false;
  public hideCreateAccountLink = false;

  constructor(private router: Router) { }

  public navigateTo (url) {
    this.router.navigateByUrl(url);
  }

  public navigateToExternal (url) {
    window.location.href = url;
  }

  public toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  public toggleUserMenu() {
    this.isUserOpen = !this.isUserOpen;
    if (this.isUserOpen) {
      if (this.user[0] && !this.user[0].roles.includes('anonymous')) {
        this.hideCreateAccountLink = true;
      }
    }
  }

  public closeMenu() {
    this.isOpen = false;
  }

  public closeUserMenu() {
    this.isUserOpen = false;
  }

  public checkForLogout(item) {
    if (item.text === 'Sign Out') {
      this.userLogOut.emit();
    } else {
      window.location.href = item.link;
    }
  }
}
